import { OrderingRule } from './OrderingRule';
import { Company } from './Company';
import { SinnoAppSettings } from './SinnoAppSettings';
import { BrandInfos } from './BrandInfos';
import { Branch } from './Branch';
import { authHeader } from '@/OAuth';
import { MiddlewareContractViolation } from '@/MiddlewareContractViolation';
import { BranchSummary } from './BranchSummary';
import { BranchPictures } from './BranchPictures';

export class CompanyDashboardApi {
  private readonly baseUrl: string;
  private readonly companyId: string;
  private readonly accessToken: string;

  public constructor(baseUrl: string, companyId: string, accessToken: string) {
    this.baseUrl = baseUrl;
    this.companyId = companyId;
    this.accessToken = accessToken;
  }

  private get authHeader() {
    return authHeader(this.accessToken);
  }

  public async *getDefaultCompanyLogo(): AsyncGenerator<
    string | null,
    void,
    unknown
  > {
    const url = new URL(`${this.baseUrl}/SinnoApp/DefaultCompanyLogo`);

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status != 404) {
            throw new MiddlewareContractViolation(
              `unexpected status ${response.status}`,
              'dashboard',
              'branches'
            );
          }

          return null;
        }

        return response.blob();
      })
      .then((response) => {
        return response == null ? response : URL.createObjectURL(response);
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    yield response;
  }

  public async *getCompany(): AsyncGenerator<Company, void, unknown> {
    const url = new URL(`${this.baseUrl}/Companies/${this.companyId}`);

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'company'
      );
    }

    const json = (await response.json()) as CompanyGetJson;
    yield {
      companyId: json.CompanyId,
      companyName: json.CompanyName,
      technicalContactEmail: json.TechnicalContactEmail,
      technicalContactSms: json.TechnicalContactSms
    };
  }

  public async putCompany(company: Company): Promise<void> {
    const json: CompanyPutJson = {
      CompanyName: company.companyName,
      TechnicalContactEmail: company.technicalContactEmail,
      TechnicalContactSms: company.technicalContactSms
    };

    const url = new URL(`${this.baseUrl}/Companies/${this.companyId}`);

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(json)
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'company'
      );
    }
  }

  public async *getCompanyLogo(): AsyncGenerator<string | null, void, unknown> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/SinnoAppSettings/CompanyLogo`
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status != 404) {
            throw new MiddlewareContractViolation(
              `unexpected status ${response.status}`,
              'dashboard',
              'companyLogo'
            );
          }

          return null;
        }

        return response.blob();
      })
      .then((response) => {
        return response == null ? response : URL.createObjectURL(response);
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    yield response;
  }

  public async *getSinnoAppSettings(): AsyncGenerator<
    SinnoAppSettings,
    void,
    unknown
  > {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/SinnoAppSettings`
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'sinnoAppSettings'
      );
    }
    const json = (await response.json()) as SinnoAppSettingsGetJson;
    console.log('test', json);
    yield {
      brandInfos: json.BrandInfos,
      click2DateURL: json.Click2DateURL,
      privacyPolicyURLDefault: json.PrivacyPolicyURLDefault,
      GTC: json.GTC,
      cancellingInstructions: json.CancellingInstructions
    };
  }

  public async patchSinnoAppSettings(
    sinnoAppSettings: SinnoAppSettings
  ): Promise<void> {
    const json: SinnoAppSettingsPatchJson = {
      BrandInfos: sinnoAppSettings.brandInfos,
      Click2DateURL: sinnoAppSettings.click2DateURL,
      PrivacyPolicyURLDefault: sinnoAppSettings.privacyPolicyURLDefault,
      GTC: sinnoAppSettings.GTC,
      CancellingInstructions: sinnoAppSettings.cancellingInstructions
    };

    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/SinnoAppSettings`
    );

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader,
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      body: JSON.stringify(json)
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'patchSinnoAppSettings'
      );
    }
  }

  public async postSinnoAppSettingsCompanyLogo(file: File): Promise<Response> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/SinnoAppSettings/CompanyLogo`
    );

    const data = new FormData();
    data.append('file', file);

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader
      },
      method: 'POST',
      body: data
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'postSinnoAppSettingsCompanyLogo'
      );
    }

    return response;
  }

  public async *getBranches(): AsyncGenerator<Branch[], void, unknown> {
    const url = new URL(`${this.baseUrl}/Companies/${this.companyId}/Branches`);

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'branches'
      );
    }

    const json = (await response.json()) as BranchesGetJson;
    yield json.Branches.map((branch) => ({
      branchId: branch.BranchId,
      branchIdIPRO: branch.BranchIdIPRO,
      branchName: branch.BranchName,
      branchPictures: branch.BranchPictures,
      brandInfos: branch.BrandInfos,
      click2DateURL: branch.Click2DateURL,
      hiddenInApp: branch.HiddenInApp,
      language: branch.Language,
      privacyPolicyURL: branch.PrivacyPolicyURL,
      summary: branch.Summary,
      url: branch.URL,
      GTC: branch.GTC,
      cancellingInstructions: branch.CancellingInstructions
    }));
  }

  public async putBranch(branch: Branch): Promise<void> {
    const json: BranchPutJson = {
      BranchName: branch.branchName,
      BrandInfos: branch.brandInfos,
      Click2DateURL: branch.click2DateURL,
      HiddenInApp: branch.hiddenInApp,
      Language: branch.language,
      PrivacyPolicyURL: branch.privacyPolicyURL,
      Summary: branch.summary,
      URL: branch.url,
      GTC: branch.GTC,
      CancellingInstructions: branch.cancellingInstructions
    };

    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/Branches/${branch.branchId}`
    );

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(json)
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'branches'
      );
    }
  }

  public async *getBranchLogo(
    branch: Branch
  ): AsyncGenerator<string | null, void, unknown> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/Branches/${branch.branchId}/BranchLogo`
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status != 404) {
            throw new MiddlewareContractViolation(
              `unexpected status ${response.status}`,
              'dashboard',
              'branches'
            );
          }

          return null;
        }

        return response.blob();
      })
      .then((response) => {
        return response == null ? response : URL.createObjectURL(response);
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    yield response;
  }

  public async postBranchLogo(branch: Branch, file: File): Promise<Response> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/Branches/${branch.branchId}/BranchLogo`
    );

    const data = new FormData();
    data.append('file', file);

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader
      },
      method: 'POST',
      body: data
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'postBranchLogo'
      );
    }

    return response;
  }

  public async postBranchPresentationMedia(
    branch: Branch,
    file: File
  ): Promise<Response> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/Branches/${branch.branchId}/PresentationMedia`
    );

    const data = new FormData();
    data.append('file', file);

    console.log(file);

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader
      },
      method: 'POST',
      body: data
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'postBranchLogo'
      );
    }

    return response;
  }

  public async *getBranchPresentationMedia(
    branch: Branch,
    index: number
  ): AsyncGenerator<string | null, void, unknown> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/Branches/${branch.branchId}/PresentationMedia/${index}`
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status != 404) {
            throw new MiddlewareContractViolation(
              `unexpected status ${response.status}`,
              'dashboard',
              'branches'
            );
          }

          return null;
        }

        return response.blob();
      })
      .then((response) => {
        return response == null ? response : URL.createObjectURL(response);
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    yield response;
  }

  public async *deleteBranchPresentationMedia(
    branch: Branch,
    index: number
  ): AsyncGenerator<Response, void, void> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/Branches/${branch.branchId}/PresentationMedia/${index}`
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'sinnoAppSettings'
      );
    }

    yield response;
  }

  public async *getOrderingRules(): AsyncGenerator<
    OrderingRule[],
    void,
    unknown
  > {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/OrderingRules`
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'GET'
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'orderingRules'
      );
    }

    const json = (await response.json()) as OrderingRule[];
    yield json;
  }

  public async patchOrderingRule(orderingRule: OrderingRule): Promise<void> {
    const json: OrderingRuleSaveJson = {
      BranchId: orderingRule.BranchId,
      Country: orderingRule.Country,
      TotalPrice: orderingRule.TotalPrice,
      DeliveryPrice: orderingRule.DeliveryPrice
    };

    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/OrderingRules/${orderingRule.RuleId}`
    );

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader,
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      body: JSON.stringify(json)
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'patchOrderingRule'
      );
    }
  }

  public async *deleteOrderingRule(
    ruleId: number
  ): AsyncGenerator<Response, void, void> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/OrderingRules/${ruleId}`
    );

    const response = await fetch(url.toString(), {
      headers: this.authHeader,
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'sinnoAppSettings'
      );
    }

    yield response;
  }

  public async postOrderingRule(rule: OrderingRule): Promise<Response> {
    const url = new URL(
      `${this.baseUrl}/Companies/${this.companyId}/OrderingRules`
    );

    const json: OrderingRuleSaveJson = {
      BranchId: rule.BranchId,
      Country: rule.Country,
      TotalPrice: rule.TotalPrice,
      DeliveryPrice: rule.DeliveryPrice
    };

    const response = await fetch(url.toString(), {
      headers: {
        ...this.authHeader,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(json)
    });

    if (!response.ok) {
      throw new MiddlewareContractViolation(
        `unexpected status ${response.status}`,
        'dashboard',
        'postSinnoAppSettingsCompanyLogo'
      );
    }

    return response;
  }
}

interface BranchesGetJson {
  Branches: {
    BranchId: number;
    BranchIdIPRO: number | null;
    BranchName: string | null;
    BranchPictures: BranchPictures;
    BrandInfos: BrandInfos;
    Click2DateURL: string | null;
    HiddenInApp: boolean;
    Language: string;
    PrivacyPolicyURL: string | null;
    Summary: BranchSummary[] | null;
    URL: string | null;
    GTC: string | null;
    CancellingInstructions: string | null;
  }[];
}

interface BranchPutJson {
  BranchName: string | null;
  BrandInfos: BrandInfos | null;
  Click2DateURL: string | null;
  HiddenInApp: boolean | null;
  Language: string;
  PrivacyPolicyURL: string | null;
  Summary: BranchSummary[] | null;
  URL: string | null;
  GTC: string | null;
  CancellingInstructions: string | null;
}

interface CompanyGetJson {
  CompanyId: number;
  CompanyName: string;
  TechnicalContactEmail: string;
  TechnicalContactSms: string;
}

interface CompanyPutJson {
  CompanyName: string;
  TechnicalContactEmail: string;
  TechnicalContactSms: string;
}

interface SinnoAppSettingsGetJson {
  BrandInfos: BrandInfos | null;
  Click2DateURL: string | null;
  PrivacyPolicyURLDefault: string;
  GTC: string | null;
  CancellingInstructions: string | null;
}

interface SinnoAppSettingsPatchJson {
  BrandInfos: BrandInfos | null;
  Click2DateURL: string | null;
  PrivacyPolicyURLDefault: string;
  GTC: string | null;
  CancellingInstructions: string | null;
}

interface OrderingRuleSaveJson {
  BranchId: number | null;
  Country: string | null;
  TotalPrice: number;
  DeliveryPrice: number;
}
