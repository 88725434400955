import en from 'vuetify/src/locale/en';
import countriesEn from './countries-en';

export default {
  ...en,
  ...countriesEn,

  dashboardTitel: 'End customer app dashboard',
  orderingRulesTitle: 'Order',

  menu_startLink: 'Start',
  menu_companyLink: 'Default settings',
  menu_branchLink: 'Branch settings',
  menu_moduleSettingsLink: 'Module settings',
  menu_modulesLink: 'Modules (tiles)',
  menu_statisticLink: 'Statistics',
  menu_iConnectCloudLink: 'i.Connect Cloud',

  loadingPleaseWait: 'Please wait...',

  showItem: 'Show',
  editItem: 'Edit',
  saveItem: 'Save',
  cancelItem: 'Abort',

  goBack: 'Back',

  numberShort: 'Nr',
  designation: 'Designation',

  preview: 'Preview',

  errors_noPermissionRoleMissing:
    'You do not have access rights! (role missing)',
  errors_WinIproInteropError:
    'An error occurred in the communication with winIPRO!',
  errors_MiddlewareContractViolation:
    'An error occurred in communication with the cloud!',
  errors_unknownError: 'An unknown error has occurred!',

  form_noValidEmail: 'No valid email-Address!',
  form_noValidURL: 'Not a valid URL!',
  form_required: 'Input required!',
  form_maxFileSize1MB: 'The maximum file size of a file is 1 MB!',
  form_totalPriceIncorrectValue: 'Incorrect total price.',
  form_deliveryPriceIncorrectValue: 'Incorrect delivery price.',

  companyView_brandInfos: 'Color settings',
  companyView_brandInfosPrimaryColor: 'Main color',
  companyView_brandInfosPrimaryContrastColor: 'Contrast to the main color',
  companyView_brandInfosSecondaryColor: 'Secondary color',
  companyView_brandInfosSecondaryContrastColor:
    'Contrast to the secondary color',
  companyView_brandInfosTextColor: 'Font color',
  companyView_brandInfosTextHeaderColor: 'Font color column title',
  companyView_brandInfosTextLinkColor: 'Text link color',
  companyView_companyBasics: 'Basic data',
  companyView_companyLogo: 'Corporate logo',
  companyView_companyLogoNew: 'New company logo',
  companyView_companyName: 'Company name',
  companyView_companyLogoDefaultSinnoLogo:
    'The logo is the SINNO default logo!',
  companyView_emailSettings: 'Email settings',
  companyView_errorMessageCantSaveCompany:
    'Some company settings could not be saved! (Company)',
  companyView_errorMessageCantSaveNewCompanyLogo:
    'New company logo could not be saved!',
  companyView_errorMessageCantSaveSinnoAppSettings:
    'Some company settings could not be saved!  (SinnoAppSettings)',
  companyView_loadingCompanyData: 'Load default settings...',
  companyView_technicalContactEmail: 'Email of the technician',
  companyView_technicalContactSms: `Technician's mobile number for SMS`,
  companyView_privacyPolicyURL: 'Privacy URL',
  companyView_GTC: 'AGB URL',
  companyView_cancellingInstructions: 'Link to the cancellation policy',
  companyView_click2DateURL: 'Click2Date URL',

  branchesView_branchBasics: 'Basic data',
  branchesView_branchLogo: 'Branch logo',
  branchesView_branchLogoDefaultCompanyLogo:
    'The logo comes from the default settings!',
  branchesView_branchLogoDefaultSinnoLogo:
    'The logo is the SINNO default logo!',
  branchesView_branchName: 'Branch name',
  branchesView_branchNamePlaceholder: 'Name of the branch',
  branchesView_branchPresentationMedia: 'Branch pictures',
  branchesView_branchPresentationMediaUpload: 'Upload new images',
  branchesView_brandInfos: 'Color settings',
  branchesView_brandInfosUseOwnInfos: 'Use custom color settings',
  branchesView_brandInfosPrimaryColor: 'Main color',
  branchesView_brandInfosPrimaryContrastColor: 'Contrast to the main color',
  branchesView_brandInfosSecondaryColor: 'Secondary color',
  branchesView_brandInfosSecondaryContrastColor:
    'Contrast to the secondary color',
  branchesView_brandInfosTextColor: 'Font color',
  branchesView_brandInfosTextHeaderColor: 'Font color column title',
  branchesView_brandInfosTextLinkColor: 'Text link color',
  branchesView_click2DateURL: 'Click2Date URL',
  branchesView_errorMessageCantSaveBranchData:
    'Some branch data could not be saved!',
  branchesView_errorMessageCantSaveNewBranchLogo:
    'New store logo could not be saved!',
  branchesView_errorMessageCantSaveNewBranchPicture:
    'A new branch image could not be saved!',
  branchesView_hiddenInApp: 'Show branch',
  branchesView_infosForColors: 'Information about the color settings',
  branchesView_loadingBranchData: 'Loading branch data...',
  branchesView_loadingBranchLogo: 'Loading branch logo...',
  branchesView_loadingbranchPictures: 'Loading branch images...',
  branchesView_loadingCompanySettings: 'Lade Standardeinstellungen...',
  branchesView_privacyPolicyURL: 'Privacy URL',
  branchesView_GTC: 'GTC URL',
  branchesView_cancellingInstructions: 'Link to the cancellation policy',
  branchesView_selectNewBranchLogo: 'Select new store logo',
  branchesView_showInApp: 'Show in app',
  branchesView_summary: 'Further information',
  branchesView_summaryTitle: 'Title',
  branchesView_summaryText: 'Text',
  branchesView_summaryAddToolTip: 'Add new branch information',
  branchesView_url: 'Internet address',

  orderingRules_Country: 'Country',
  orderingRules_Branch: 'Branch',
  orderingRules_totalPrice: 'Total price up to',
  orderingRules_until: 'until',
  orderingRules_deliveryPrice: 'Delivery price',

  moduleSettings_Module: 'Module',
  moduleSettings_Action: 'Edit',
  moduleSettings_Module_ReOrder: 'Order',

  deleteOrderingRule_Confirmation: 'Really delete delivery price?',
  confirmation_yes: 'Yes',
  confirmation_no: 'No',

  orderingRuleView_formTitle_new: 'Create delivery price',
  orderingRuleView_formTitle: 'Delivery price',
  orderingRuleView_country: 'Country',
  orderingRuleView_branch: 'Branch',
  orderingRuleView_errorMessageCantSaveOrderingRule:
    'Request error while saving Ordering Rule.',
  orderingRuleView_deliveryPrice: 'Delivery prices',
  country_empty: 'All',
  branch_empty: 'All',
  orderingRule_allBranches: 'All',
  orderingRule_allCountries: 'All',
  orderingRules_noData: 'There are currently no delivery prices defined',
  orderingRuleView_intro: `Rules for calculating the delivery price:
  1. Only prices where the country of the delivery address corresponds to the country of the price rule are taken into account. If no prices are defined for a country, the prices for "all countries" are used.
  2. It is then checked whether there are separate prices for the customer's favorite branch and, if so, only this price determination is used. If there are no prices for the favorite store, the prices for "all stores" are used.
  3. Finally, it is checked whether the total price of the goods is less than or equal to the value "Total price to" and the corresponding delivery price is used.`
};
