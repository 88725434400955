import de from 'vuetify/src/locale/de';
import countriesDe from './countries-de';

export default {
  ...de,
  ...countriesDe,

  dashboardTitel: 'Endkunden-App Dashboard',
  orderingRulesTitle: 'Bestellung',

  menu_startLink: 'Start',
  menu_companyLink: 'Standardeinstellungen',
  menu_branchLink: 'Filialeinstellungen',
  menu_moduleSettingsLink: 'Moduleinstellungen',
  menu_modulesLink: 'Module (Kacheln)',
  menu_statisticLink: 'Statistiken',
  menu_iConnectCloudLink: 'i.Connect Cloud',

  loadingPleaseWait: 'Bitte warten...',

  showItem: 'Anzeigen',
  editItem: 'Bearbeiten',
  saveItem: 'Speichern',
  cancelItem: 'Abbruch',

  goBack: 'Zurück',

  numberShort: 'Nr',
  designation: 'Bezeichnung',

  preview: 'Vorschau',

  errors_noPermissionRoleMissing:
    'Sie haben leider keine Zugriffsrechte! (Rolle fehlt)',
  errors_WinIproInteropError:
    'Ein Fehler in der Kommunikation mit winIPRO ist aufgetreten!',
  errors_MiddlewareContractViolation:
    'Ein Fehler in der Kommunikation mit der Cloud ist aufgetreten!',
  errors_unknownError: 'Ein unbekannter Fehler ist aufgetreten!',

  form_noValidEmail: 'Keine gültige E-Mail-Adresse!',
  form_noValidURL: 'Keine gültige URL!',
  form_required: 'Eingabe erforderlich!',
  form_maxFileSize1MB: 'Die maximale Dateigröße einer Datei beträgt 1 MB!',
  form_totalPriceIncorrectValue: 'Ungültiger Gesamtpreis',
  form_deliveryPriceIncorrectValue: 'Ungültiger Lieferpreis',

  companyView_brandInfos: 'Farbeinstellungen',
  companyView_brandInfosPrimaryColor: 'Hauptfarbe',
  companyView_brandInfosPrimaryContrastColor: 'Kontrast zur Hauptfarbe',
  companyView_brandInfosSecondaryColor: 'Nebenfarbe',
  companyView_brandInfosSecondaryContrastColor: 'Kontrast zur Nebenfarbe',
  companyView_brandInfosTextColor: 'Schriftfarbe',
  companyView_brandInfosTextHeaderColor: 'Schriftfarbe Spaltentitel',
  companyView_brandInfosTextLinkColor: 'Textlinkfarbe',
  companyView_companyBasics: 'Grunddaten',
  companyView_companyLogo: 'Unternehmenslogo',
  companyView_companyLogoNew: 'Neues Unternehmenslogo',
  companyView_companyName: 'Unternehmensname',
  companyView_companyLogoDefaultSinnoLogo:
    'Das Logo ist das SINNO Standardlogo!',
  companyView_emailSettings: 'E-Mail Einstellungen',
  companyView_errorMessageCantSaveCompany:
    'Einige Unternehmenseinstellungen konnten nicht gespeichert werden! (Company)',
  companyView_errorMessageCantSaveNewCompanyLogo:
    'Neues Unternehmenslogo konnte nicht gespeichert werden!',
  companyView_errorMessageCantSaveSinnoAppSettings:
    'Einige Unternehmenseinstellungen konnten nicht gespeichert werden! (SinnoAppSettings)',
  companyView_loadingCompanyData: 'Lade Standardeinstellungen...',
  companyView_technicalContactEmail: 'E-Mail des Technikers',
  companyView_technicalContactSms: 'Mobilnummer des Technikers für SMS',
  companyView_privacyPolicyURL: 'Datenschutz URL',
  companyView_GTC: 'AGB URL',
  companyView_cancellingInstructions: 'Link zur den Widerrufsbelehrungen',
  companyView_click2DateURL: 'Click2Date URL',

  branchesView_branchBasics: 'Grunddaten',
  branchesView_branchLogo: 'Filiallogo',
  branchesView_branchLogoDefaultCompanyLogo:
    'Das Logo kommt aus den Standardeinstellungen!',
  branchesView_branchLogoDefaultSinnoLogo:
    'Das Logo ist das SINNO Standardlogo!',
  branchesView_branchName: 'Filialname',
  branchesView_branchNamePlaceholder: 'Name der Filiale',
  branchesView_branchPresentationMedia: 'Filialbilder',
  branchesView_branchPresentationMediaUpload: 'Neue Bilder hochladen',
  branchesView_brandInfos: 'Farbeinstellungen',
  branchesView_brandInfosUseOwnInfos: 'Eigene Farbeinstellungen verwenden',
  branchesView_brandInfosPrimaryColor: 'Hauptfarbe',
  branchesView_brandInfosPrimaryContrastColor: 'Kontrast zur Hauptfarbe',
  branchesView_brandInfosSecondaryColor: 'Nebenfarbe',
  branchesView_brandInfosSecondaryContrastColor: 'Kontrast zur Nebenfarbe',
  branchesView_brandInfosTextColor: 'Schriftfarbe',
  branchesView_brandInfosTextHeaderColor: 'Schriftfarbe Spaltentitel',
  branchesView_brandInfosTextLinkColor: 'Textlinkfarbe',
  branchesView_click2DateURL: 'Click2Date URL',
  branchesView_errorMessageCantSaveBranchData:
    'Einige Filialdaten konnten nicht gespeichert werden!',
  branchesView_errorMessageCantSaveNewBranchLogo:
    'Neues Filiallogo konnte nicht gespeichert werden!',
  branchesView_errorMessageCantSaveNewBranchPicture:
    'Ein neues Filialbild konnte nich gespeichert werden!',
  branchesView_hiddenInApp: 'Filiale anzeigen',
  branchesView_infosForColors: 'Infos zu den Farbeinstellungen',
  branchesView_loadingBranchData: 'Lade Filialdaten...',
  branchesView_loadingBranchLogo: 'Lade Filiallogo...',
  branchesView_loadingbranchPictures: 'Lade Filialbilder...',
  branchesView_loadingCompanySettings: 'Lade Standardeinstellungen...',
  branchesView_privacyPolicyURL: 'Datenschutzlink',
  branchesView_GTC: 'AGB-Link',
  branchesView_cancellingInstructions: 'Link zur den Widerrufsbelehrungen',
  branchesView_selectNewBranchLogo: 'Neues Filiallogo auswählen',
  branchesView_showInApp: 'In App anzeigen',
  branchesView_summary: 'Zusatzinformationen',
  branchesView_summaryTitle: 'Titel',
  branchesView_summaryText: 'Text',
  branchesView_summaryAddToolTip: 'Neue Filialinformation hinzufügen',
  branchesView_url: 'Internetadresse',

  orderingRules_Country: 'Land',
  orderingRules_Branch: 'Filiale',
  orderingRules_totalPrice: 'Gesamtpreis bis',
  orderingRules_until: 'bis',
  orderingRules_deliveryPrice: 'Lieferpreis',

  moduleSettings_Module: 'Modul',
  moduleSettings_Action: 'Bearbeiten',
  moduleSettings_Module_ReOrder: 'Bestellung',

  deleteOrderingRule_Confirmation: 'Lieferpreis wirklich löschen?',
  confirmation_yes: 'Ja',
  confirmation_no: 'Nein',

  orderingRuleView_formTitle_new: 'Lieferpreis anlegen',
  orderingRuleView_formTitle: 'Lieferpreis',
  orderingRuleView_country: 'Land',
  orderingRuleView_branch: 'Filiale',
  orderingRuleView_errorMessageCantSaveOrderingRule:
    'Request error while saving Ordering Rule.',
  orderingRuleView_deliveryPrice: 'Lieferpreise',
  country_empty: 'Alle',
  branch_empty: 'Alle',
  orderingRule_allBranches: 'Alle',
  orderingRule_allCountries: 'Alle',
  orderingRules_noData: 'Es sind aktuell keine Lieferpreise definiert',
  orderingRuleView_intro: `Regeln für die Berechnung des Lieferpreises:
  1. Es werden nur Preise berücksichtigt bei dem das Land der Lieferadresse dem Land  der Preisregel entspricht. Sollten keine Preise für ein Land definiert sein, werden die Preise für "alle Länder" herangezogen.
  2. Anschließend wird geprüft, ob es für die Favoritenfiliale des Kunden eigene Preise gibt und gegebenenfalls nur dies Preisermittlung verwendet. Sind keine Preise für die Favoritenfiliale vorhanden, werden die Preise für "alle Filialen" herangezogen.
  3. Zum Schluss wird geprüft ob der Gesamtpreis der Waren kleiner oder gleich dem Wert "Gesamtpreis bis" entspricht und der entsprechende Lieferpreis verwendet.`
};
