import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import vuetify from './plugins/vuetify'

// IE11 Support
import 'babel-polyfill'

Vue.config.productionTip = false

Vue.prototype.$colors = {
  PrimaryColor: '#0062ae',
  PrimaryContrastColor: '#ffffff'
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
