import { authHeader } from '@/OAuth';
// Your own translation file
import de from '../lang/de';
import en from '../lang/en';

// AppId 4 = AppName SinnoDashboard.
const APP_ID = 4;

export class TranslationAPI {
  private readonly baseUrl: string;
  private readonly companyId: string;
  private readonly accessToken: string;

  public constructor(baseUrl: string, companyId: string, accessToken: string) {
    this.baseUrl = baseUrl;
    this.companyId = companyId;
    this.accessToken = accessToken;
  }

  public async loadTranslation(langCode: string): Promise<any> {
    const langId = await this.getLangIdByCode(langCode);
    return new Promise((resolve) => {
      resolve(langCode === 'de' ? de : en);
    });

    // TODO: Switch to API when it's ready.
    const url = new URL(
      `${this.baseUrl}/App/${APP_ID}/Languages/${langId}/Placeholders`
    );

    return fetch(url.toString(), {
      headers: authHeader(this.accessToken),
      method: 'GET'
    });
  }

  public async getLangIdByCode(langCode: string): Promise<number | undefined> {
    const dummyResponse = {
      Languages: [
        {
          LanguageId: 1,
          LanguageString: 'en'
        },
        {
          LanguageId: 2,
          LanguageString: 'de'
        }
      ]
    };

    // TODO: Switch to API when it's ready.
    return new Promise((resolve) => {
      resolve(
        dummyResponse.Languages.find(
          (language) => language.LanguageString === langCode
        )?.LanguageId
      );
    });
  }
}
