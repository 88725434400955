<template>
  <svg id="settings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :width="width" :height="height">
  <g>
    <path class="cls-1" d="M6.75,10,5,7.1,7.1,5,10,6.75h0A8,8,0,0,1,11.84,6l.83-3.33h3L16.5,6a8,8,0,0,1,1.8.75h0L21.24,5,23.37,7.1,21.6,10h0a8,8,0,0,1,.75,1.8l3.33.83v3l-3.33.83a8,8,0,0,1-.75,1.8h0l1.77,2.94-2.13,2.13L18.3,21.6h0a8,8,0,0,1-1.8.75l-.83,3.33h-3l-.83-3.33a8,8,0,0,1-1.8-.75h0L7.1,23.37,5,21.24,6.75,18.3h0A8,8,0,0,1,6,16.5l-3.33-.83v-3L6,11.84A8,8,0,0,1,6.75,10Z" transform="translate(-2.17 -2.17)"/>
    <circle class="cls-1" cx="12" cy="12" r="5"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'GearIcon',
  props: {
    width: {
      type: [Number, String],
      default: 50
    },
    height: {
      type: [Number, String],
      default: 50
    }
  }
}
</script>

<style>
  .cls-1 {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
  }
</style>