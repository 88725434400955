
  import Vue from 'vue'

  export default Vue.extend({
    name: 'Menu',

    data: () => ({
      selectedItem: 1,
      menu: [
        /*{
          id: 1,
          name: "Übersicht",
          icon: "mdi-home",
          link: 'Home',
          childs: [
            {
              id: 1001
            }
          ]
        },
        {
          id: 1001,
          name: "Statistiken",
          icon: "mdi-chart-line",
          link: 'Home',
          submenu: true
        },
        {
          id: 2,
          name: "Module",
          icon: "mdi-view-module"
        },
        {
          id: 3001,
          name: "Nachrichten",
          icon: "mdi-email-outline",
          submenu: true
        },*/
        {
          id: 3,
          name: "Einstellungen",
          icon: "mdi-cog-outline"
        },
        {
          id: 3001,
          name: "Unternehmen",
          icon: "mdi-city-variant-outline",
          link: "Company",
          submenu: true
        },
        {
          id: 3002,
          name: "Filialen",
          icon: "mdi-home-outline",
          link: "Branches",
          submenu: true
        },
        /*{
          id: 3003,
          name: "Module",
          icon: "mdi-view-module",
          submenu: true
        }*/
      ]
    }),
  })
