export default {
  country_DEU: 'Germany',
  country_AUT: 'Austria',
  country_CHE: 'Switzerland',
  country_LUX: 'Luxembourg',
  country_FRA: 'France',
  country_ITA: 'Italy',
  country_ESP: 'Spain',
  country_GBR: 'United Kingdom',
  country_NLD: 'Netherlands',
  country_BEL: 'Belgium',
  country_DNK: 'Denmark',
  country_LIE: 'Liechtenstein',
  country_NOR: 'Norway',
  country_GRC: 'Greece',
  country_HUN: 'Hungary',
  country_SWE: 'Sweden',
  country_FIN: 'Finland',
  country_PRT: 'Portugal',
  country_ROU: 'Romania',
  country_ISL: 'Iceland',
  country_AND: 'Andorra',
  country_USA: 'United States of America',
  country_SAU: 'Saudi Arabia',
  country_PYF: 'French Polynesia',
  country_ALB: 'Albania',
  country_AGO: 'Angola',
  country_AUS: 'Australia',
  country_BGR: 'Bulgaria',
  country_BIH: 'Bosnia and Herzegovina',
  country_BOL: 'Bolivia',
  country_BRA: 'Brazil',
  country_BLR: 'Belarus',
  country_CUB: 'Cuba',
  country_COD: 'Congo',
  country_CAN: 'Canada',
  country_COL: 'Colombia',
  country_CRI: 'Costa Rica',
  country_CYP: 'Cyprus',
  country_CZE: 'Czechia',
  country_DOM: 'Dominican Republic',
  country_DZA: 'Algeria',
  country_KEN: 'Kenya',
  country_TZA: 'Tanzania',
  country_UGA: 'Uganda',
  country_EST: 'Estonia',
  country_EGY: 'Egypt',
  country_ETH: 'Ethiopia',
  country_GHA: 'Ghana',
  country_HRV: 'Croatia',
  country_ISR: 'Israel',
  country_IND: 'India',
  country_IRN: 'Iran',
  country_IRL: 'Ireland',
  country_IRQ: 'Iraq',
  country_JPN: 'Japan',
  country_JAM: 'Jamaica',
  country_JOR: 'Jordan',
  country_KWT: 'Kuwait',
  country_LTU: 'Lithuania',
  country_LVA: 'Latvia',
  country_MLT: 'Malta',
  country_MAR: 'Morocco',
  country_MYS: 'Malaysia',
  country_MCO: 'Monaco',
  country_MDA: 'Moldova',
  country_MEX: 'Mexico',
  country_MKD: 'North Macedonia',
  country_NZL: 'New Zealand',
  country_OMN: 'Oman',
  country_POL: 'Poland',
  country_ARG: 'Argentina',
  country_TWN: 'Taiwan',
  country_CAF: 'Central African Republic',
  country_COG: 'Congo',
  country_CHL: 'Chile',
  country_IDN: 'Indonesia',
  country_LBN: 'Lebanon',
  country_KOR: 'Korea',
  country_PHL: 'Philippines',
  country_SMR: 'San Marino',
  country_TGO: 'Togo',
  country_RUS: 'Russian Federation',
  country_RWA: 'Rwanda',
  country_SGP: 'Singapore',
  country_SVK: 'Slovakia',
  country_SVN: 'Slovenia',
  country_SYR: 'Syrian Arab Republic',
  country_THA: 'Thailand',
  country_TUN: 'Tunisia',
  country_TUR: 'Türkiye',
  country_UKR: 'Ukraine',
  country_ARE: 'United Arab Emirates',
  country_VAT: 'Holy See',
  country_VNM: 'Viet Nam',
  country_NGA: 'Nigeria',
  country_MNE: 'Montenegro',
  country_VEN: 'Venezuela',
  country_ZAF: 'South Africa',
  country_MUS: 'Mauritius',
  country_MDG: 'Madagascar',
  country_SYC: 'Seychelles',
  country_SRB: 'Serbia',
  country_AZE: 'Azerbaijan',
  country_KAZ: 'Kazakhstan',
  country_QAT: 'Qatar',
  country_SLV: 'El Salvador',
  country_GTM: 'Guatemala',
  country_HND: 'Honduras',
  country_NIC: 'Nicaragua',
  country_PAN: 'Panama',
  country_ECU: 'Ecuador',
  country_PRY: 'Paraguay',
  country_PER: 'Peru',
  country_URY: 'Uruguay',
  country_ARM: 'Armenia',
  country_GEO: 'Georgia',
  country_PAK: 'Pakistan',
  country_LKA: 'Sri Lanka',
  country_MOZ: 'Mozambique',
  country_NAM: 'Namibia',
  country_ZMB: 'Zambia',
  country_ZWE: 'Zimbabwe',
  country_BWA: 'Botswana',
  country_MWI: 'Malawi',
  country_YEM: 'Yemen',
  country_SEN: 'Senegal',
  country_CIV: `Côte d'Ivoire`,
  country_TKM: 'Turkmenistan',
  country_LBY: 'Libya',
  country_CHN: 'China'
};
