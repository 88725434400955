export default {
  country_DEU: 'Deutschland',
  country_AUT: 'Österreich',
  country_CHE: 'Schweiz',
  country_LUX: 'Luxemburg',
  country_FRA: 'Frankreich',
  country_ITA: 'Italien',
  country_ESP: 'Spanien',
  country_GBR: 'Großbritannien',
  country_NLD: 'Niederlande',
  country_BEL: 'Belgien',
  country_DNK: 'Dänemark',
  country_LIE: 'Liechtenstein',
  country_NOR: 'Norwegen',
  country_GRC: 'Griechenland',
  country_HUN: 'Ungarn',
  country_SWE: 'Schweden',
  country_FIN: 'Finnland',
  country_PRT: 'Portugal',
  country_ROU: 'Rumänien',
  country_ISL: 'Island',
  country_AND: 'Andorra',
  country_USA: 'Vereinigte Staaten',
  country_SAU: 'Saudi-Arabien',
  country_PYF: 'Französisch-Polynesien',
  country_ALB: 'Albanien',
  country_AGO: 'Angola',
  country_AUS: 'Australien',
  country_BGR: 'Bulgarien',
  country_BIH: 'Bosnien und Herzegowina',
  country_BOL: 'Bolivien',
  country_BRA: 'Brasilien',
  country_BLR: 'Belarus',
  country_CUB: 'Kuba',
  country_COD: 'Zaire',
  country_CAN: 'Kanada',
  country_COL: 'Kolumbien',
  country_CRI: 'Costa Rica',
  country_CYP: 'Zypern',
  country_CZE: 'Tschechien',
  country_DOM: 'Dominikanische Republik',
  country_DZA: 'Algerien',
  country_KEN: 'Kenia',
  country_TZA: 'Tansania',
  country_UGA: 'Uganda',
  country_EST: 'Estland',
  country_EGY: 'Ägypten',
  country_ETH: 'Äthiopien',
  country_GHA: 'Ghana',
  country_HRV: 'Kroatien',
  country_ISR: 'Israel',
  country_IND: 'Indien',
  country_IRN: 'Iran',
  country_IRL: 'Irland',
  country_IRQ: 'Irak',
  country_JPN: 'Japan',
  country_JAM: 'Jamaika',
  country_JOR: 'Jordanien',
  country_KWT: 'Kuwait',
  country_LTU: 'Litauen',
  country_LVA: 'Lettland',
  country_MLT: 'Malta',
  country_MAR: 'Marokko',
  country_MYS: 'Malaysia',
  country_MCO: 'Monaco',
  country_MDA: 'Moldau',
  country_MEX: 'Mexiko',
  country_MKD: 'Nordmazedonien',
  country_NZL: 'Neuseeland',
  country_OMN: 'Oman',
  country_POL: 'Polen',
  country_ARG: 'Argentinien',
  country_TWN: 'Taiwan',
  country_CAF: 'Zentralafrikanische Republik',
  country_COG: 'Kongo-Brazzaville',
  country_CHL: 'Chile',
  country_IDN: 'Indonesien',
  country_LBN: 'Libanon',
  country_KOR: 'Südkorea',
  country_PHL: 'Philippinen',
  country_SMR: 'San Marino',
  country_TGO: 'Togo',
  country_RUS: 'Russland',
  country_RWA: 'Ruanda',
  country_SGP: 'Singapur',
  country_SVK: 'Slowakei',
  country_SVN: 'Slowenien',
  country_SYR: 'Syrien',
  country_THA: 'Thailand',
  country_TUN: 'Tunesien',
  country_TUR: 'Türkei',
  country_UKR: 'Ukraine',
  country_ARE: 'Vereinigte Arabische Emirate',
  country_VAT: 'Vatikanstadt',
  country_VNM: 'Vietnam',
  country_NGA: 'Nigeria',
  country_MNE: 'Montenegro',
  country_VEN: 'Venezuela',
  country_ZAF: 'Südafrika',
  country_MUS: 'Mauritius',
  country_MDG: 'Madagaskar',
  country_SYC: 'Seychellen',
  country_SRB: 'Serbien',
  country_AZE: 'Aserbaidschan',
  country_KAZ: 'Kasachstan',
  country_QAT: 'Katar',
  country_SLV: 'El Salvador',
  country_GTM: 'Guatemala',
  country_HND: 'Honduras',
  country_NIC: 'Nicaragua',
  country_PAN: 'Panama',
  country_ECU: 'Ecuador',
  country_PRY: 'Paraguay',
  country_PER: 'Peru',
  country_URY: 'Uruguay',
  country_ARM: 'Armenien',
  country_GEO: 'Georgien',
  country_PAK: 'Pakistan',
  country_LKA: 'Sri Lanka',
  country_MOZ: 'Mosambik',
  country_NAM: 'Namibia',
  country_ZMB: 'Sambia',
  country_ZWE: 'Simbabwe',
  country_BWA: 'Botswana',
  country_MWI: 'Malawi',
  country_YEM: 'Jemen',
  country_SEN: 'Senegal',
  country_CIV: 'Elfenbeinküste',
  country_TKM: 'Turkmenistan',
  country_LBY: 'Libyen',
  country_CHN: 'Volksrepublik China'
};
